import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import HyperCard from "../../../components/hyper-card"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopKalenderQuery {
      januar: file(
        relativePath: { eq: "workshops/kalender/januar/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      februar: file(
        relativePath: { eq: "workshops/kalender/februar/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      maerz: file(relativePath: { eq: "workshops/kalender/maerz/titel.jpg" }) {
        ...hyperCardImage
      }
      april: file(relativePath: { eq: "workshops/kalender/april/titel.jpg" }) {
        ...hyperCardImage
      }
      mai: file(relativePath: { eq: "workshops/kalender/mai/titel.jpg" }) {
        ...hyperCardImage
      }
      juni: file(relativePath: { eq: "workshops/kalender/juni/titel.jpg" }) {
        ...hyperCardImage
      }
      juli: file(relativePath: { eq: "workshops/kalender/juli/titel.jpg" }) {
        ...hyperCardImage
      }
      august: file(
        relativePath: { eq: "workshops/kalender/august/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      september: file(
        relativePath: { eq: "workshops/kalender/september/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      oktober: file(
        relativePath: { eq: "workshops/kalender/oktober/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      november: file(
        relativePath: { eq: "workshops/kalender/november/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      dezember: file(
        relativePath: { eq: "workshops/kalender/dezember/titel.jpg" }
      ) {
        ...hyperCardImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)
  return (
    <Layout backdrop="workshop-kalender">
      <Seo
        title="Wir sammeln Wörter und Bilder für das Jahr"
        description="Ein Jahr steckt voller Bilder, Eindrücke und Ereignisse. Jede Jahreszeit kleidet die Welt in andere Farben und bringt neue Naturerscheinungen mit sich. Das hat schon Friedrich Hölderlin in seinen Turmgedichten ganz genau beobachtet und festgehalten. In einem Kalender-Bastel-und Schreibworkshop stellen wir Ideen zur Gestaltung einer Kalenderseite für das nächste Jahr vor."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
          ]}
        />
        <PageTitle>Wir sammeln Wörter und Bilder für das Jahr</PageTitle>
        <Heading as="div" level={5} fontFamily="serif" bold={false}>
          Ein Kalender-Bastel- und Schreibworkshop für Kinder von 8 bis 12
          Jahren
        </Heading>
        <Paragraph dropcap={true}>
          Ein Jahr steckt voller Bilder, Eindrücke und Ereignisse. Jede
          Jahreszeit kleidet die Welt in andere Farben und bringt neue
          Naturerscheinungen mit sich. Das hat schon Friedrich Hölderlin in
          seinen Turmgedichten ganz genau beobachtet und festgehalten. In einem
          Kalender-Bastel-und Schreibworkshop stellen wir euch für jeden Monat
          des Jahres eine Gestaltungsidee, inspiriert von Friedrich Hölderlin,
          vor.
        </Paragraph>
        <Paragraph>
          In unserem Museumsshop im Hölderlinturm kann dazu für 5 € ein
          passendes Set mit einem Bastelkalender und Materialien für 12
          Kalenderseiten erworben werden.
        </Paragraph>

        <HyperCard
          headingElement="h2"
          title="Januar"
          color="winter"
          image={data.januar}
          description={`Brrrr! Das neue Jahr hat begonnen und der kalte Monat Januar hält uns alle im warmen Zuhause. Der Dichter Friedrich Hölderlin war von der weißen Winterlandschaft um ihn herum so fasziniert, dass er ein Gedicht darüber geschrieben hat. Du kannst es mit einem schönen Wachsmal-Bild verzieren!`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/januar"
        />
        <HyperCard
          headingElement="h2"
          title="Februar"
          color="winter"
          image={data.februar}
          description={`Was für eine verrückte Zeit! Im Februar feiern wir Fasching. Neben Frühling, Sommer, Herbst und Winter zählen manche den Fasching sogar als die 5. Jahreszeit. Dafür wollen wir bunte Masken basteln. Zur Inspiration haben wir verschiedene Gefühlsausdrücke aus Hölderlins Gedichten für deine Masken gesammelt.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/februar"
        />
        <HyperCard
          headingElement="h2"
          title="März"
          color="spring"
          image={data.maerz}
          description={`Im Monat März wird’s bunt! Wir sammeln Farben und zeigen dir, wie man aus Wörtern und Buchstaben Bilder erschaffen kann. Bist du schon neugierig?`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/maerz"
        />
        <HyperCard
          headingElement="h2"
          title="April"
          color="spring"
          image={data.april}
          description={`Der Frühling ist da! Und mit ihm kommt ein weiteres Jahreszeitengedicht von Hölderlin. Den Frühling mochte er wohl besonders gern. In seiner Zeit im Tübinger Turm hat er ganze 9 Gedichte zum Frühling verfasst. Insgesamt kommt das Wort »Frühling« sogar 77 Mal in seinen Gedichten vor. Wir zeigen dir, wie man aus Hölderlins Frühlingsgedicht ganz leicht ein eigenes Gedicht machen kann. `}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/april"
        />
        <HyperCard
          headingElement="h2"
          title="Mai"
          color="spring"
          image={data.mai}
          description={`Für den Mai haben wir uns etwas Lustiges ausgedacht! Hölderlin war ein Meister darin, sich neue Wörter auszudenken. Diese Art von Wörtern nennt man Neologismen und sie werden meist aus schon bestehenden Wörtern neu zusammengesetzt - ein bisschen wie bei einem Puzzle. Jetzt bist du an der Reihe! Für den Mai baust du aus Hölderlins Neologismen neue Wörter zusammen und bastelst eine Collage.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/mai"
        />
        <HyperCard
          headingElement="h2"
          title="Juni"
          color="summer"
          image={data.juni}
          description={`Um den Sommer willkommen zu heißen, schreiben wir für ihn ein Gedicht in Form eines Akrostichons. Ein Akrostichon ist ein Gedicht, bei dem die Anfangsbuchstaben der Verse ein eigenes Wort ergeben. Passend zur Jahreszeit haben wir uns das Wort S-O-M-M-E-R ausgesucht.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/juni"
        />
        <HyperCard
          headingElement="h2"
          title="Juli"
          color="summer"
          image={data.juli}
          description={`In Hölderlins Gedicht ›Der Sommer‹ schimmert und flimmert es nur so am Himmel. Es beginnt nicht mit einem strahlend hellen Sommertag, sondern mit einer Sternennacht. Mach es ihm nach und zaubere einen Sommernachtshimmel auf deine Kalenderseite!`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/juli"
        />
        <HyperCard
          headingElement="h2"
          title="August"
          color="summer"
          image={data.august}
          description={`Die Sommerferien sind da. Zeit, zu träumen, zu verreisen oder einfach einmal in den Tag hineinzuleben. Wonach sehnst du dich, wenn du an die Sommerferien denkst? Welche Erinnerungen an vergangene Urlaube oder Ferienerlebnisse kommen dir in den Sinn? Verfasse dazu ein Haiku-Gedicht.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/august"
        />
        <HyperCard
          headingElement="h2"
          title="September"
          color="autumn"
          image={data.september}
          description={`Der Sommer neigt sich langsam dem Ende zu. Wir genießen die letzten warmen Tage, während der Herbst immer näher rückt. Vermisst du in der nasskalten Jahreszeit auch manchmal die Wärme und Lebendigkeit des Sommers? Über dieses Gefühl hat auch Hölderlin in seinem Gedicht ›Hälfte des Lebens‹ geschrieben. Für den September basteln wir dazu Schwäne und setzen sie in eine schöne Spätsommer-Landschaft.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/september"
        />
        <HyperCard
          headingElement="h2"
          title="Oktober"
          color="autumn"
          image={data.oktober}
          description={`Oktober färbt die Natur mit einer Palette schönster Gelb-, Orange-, Grün- und Braun-Töne. Wenn der Wind durch die Bäume weht, fallen die Blätter herunter und bilden einen weichen Teppich unter den Füßen der Spaziergänger. Das wollen wir auch im Kalender festhalten.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/oktober"
        />
        <HyperCard
          headingElement="h2"
          title="November"
          color="autumn"
          image={data.november}
          description={`Im November werden die Tage dunkler und kälter. Wir sitzen zuhause, schauen aus dem Fenster, trinken Tee ... oder eine leckere Tasse heiße Schokolade. Jeder hat dafür sein eigenes Geheimrezept. Bestimmt hast du auch eines. Schreibe es auf und bastel ein Rezeptbuch dazu.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/november"
        />
        <HyperCard
          headingElement="h2"
          title="Dezember"
          color="winter"
          image={data.dezember}
          description={`Ho, Ho, Ho! Dezember ist der Monat des Weihnachtsfestes, des Aneinander-Denkens, des Schenkens und des Zusammenseins mit den Liebsten. Für diesen letzten Monat des Jahres bereiten wir Nikolaus-Socken vor und verstecken darin ein Wort für jede Adventswoche.`}
          cta="Kalenderseite ansehen"
          to="/workshops/kalender/dezember"
        />
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
